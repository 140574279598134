import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './contact-form-styles.scss'
import { FormInfo, Form } from '../../components/form'
import { Spinner, Success, Seo } from '../../components'
import contactFormSchema from './validation-schema'

const ContactFormView = ({
  data, loading, onSubmit, success, isUpdating,
}) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    age: '',
    occupation: '',
    location: '',
    natureOfConcern: '',
    message: '',
    how: '',
    accept: false,
    accept2: false,
    marketingConsent: false,
    rememberMe: false,
  }
  const [natureOptions, setNatureOptions] = useState([])
  const [formFields, setFormFields] = useState([])
  const { t } = useTranslation()
  useEffect(() => {
    const fields = [
      {
        class: 'form__content__titles',
        formType: 'title',
        name: 'title-one',
        title: t('contactForm.form.personal.title'),
      },
      {
        label: t('contactForm.form.personal.firstName'),
        formType: 'field',
        name: 'firstName',
        type: 'text',
        class: 'col-md-6 col-12 field__container',
      },
      {
        label: t('contactForm.form.personal.lastName'),
        formType: 'field',
        name: 'lastName',
        type: 'text',
        class: 'col-md-6 col-12 field__container',
      },
      {
        label: t('contactForm.form.personal.contactNumber'),
        formType: 'field',
        name: 'contactNumber',
        type: 'text',
        class: 'col-md-6 col-12 field__container',
      },
      {
        label: t('contactForm.form.personal.email'),
        formType: 'field',
        name: 'email',
        type: 'email',
        class: 'col-md-6 col-12 field__container',
      },
      {
        label: t('contactForm.form.personal.age'),
        formType: 'field',
        name: 'age',
        type: 'text',
        class: 'col-md-6 col-12 field__container',
      },
      {
        label: t('contactForm.form.personal.occupation'),
        formType: 'field',
        name: 'occupation',
        type: 'text',
        class: 'col-md-6 col-12 field__container',
      },
      {
        label: t('contactForm.form.personal.location'),
        formType: 'field',
        name: 'location',
        type: 'text',
        class: 'col-md-6 col-12 field__container',
        placeholder: t('contactForm.form.personal.locationPlaceholder'),
      },
      {
        class: 'col-12 form__content__titles',
        formType: 'title',
        name: 'title-two',
        title: t('contactForm.form.inquiry.title'),
      },
      {
        label: t('contactForm.form.inquiry.nature'),
        formType: 'select',
        name: 'natureOfConcern',
        type: 'text',
        class: 'col-12 field__container',
        options: natureOptions,
        placeholder: t('contactForm.form.placeholder'),
      },
      {
        label: t('contactForm.form.inquiry.message'),
        formType: 'textarea',
        name: 'message',
        row: '6',
        type: 'textarea',
        class: 'col-12 field__container',
      },
      {
        class: 'col-12 form__content__titles',
        formType: 'title',
        title: t('contactForm.form.others.title'),
      },
      {
        label: t('contactForm.form.others.how'),
        placeholder: t('contactForm.form.placeholder'),
        formType: 'select',
        name: 'how',
        type: 'text',
        class: 'col-12 field__container',
        options: [
          {
            value: 'google',
            label: 'Google Search',
          },
          {
            value: 'facebook',
            label: 'Facebook',
          },
          {
            value: 'instagram',
            label: 'Instagram',
          },
          {
            value: 'family',
            label: 'Family',
          },
          {
            value: 'Friends / Colleagues',
            label: 'Friends / Colleagues',
          },
          {
            value: 'events',
            label: 'Events',
          },
          {
            value: 'Billboards / Out-of-Home Ads',
            label: 'Billboards / Out-of-Home Ads',
          },
          {
            value: 'Viber / Text message',
            label: 'Viber / Text message',
          },
          {
            value: 'Others',
            label: 'Others',
          },
        ],
      },
      {
        formType: 'field',
        // name: 'marketingConsent',        
        name: 'accept',        
        type: 'checkbox',
        class: 'col-12 field__checkbox',
        text: _.get(data, 'consent_text'),
      },
      {
        formType: 'field',
        name: 'accept2',        
        // name: 'marketingConsent', 
        
        type: 'checkbox',
        class: 'col-12 field__checkbox',
        text: _.get(data, 'terms_and_condition'),
      },
    ]    
    setFormFields(fields)
  }, [natureOptions])

  useEffect(() => {
    const optionsNarure = _.reduce(
      _.get(data, 'nature_of_concern'),
      (result, value, key) => {
        result.push({
          value: key,
          label: value,
        })
        return result
      },
      [],
    )
    setNatureOptions(optionsNarure)
  }, [data])

  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <div className="container-fluid contactForm__container">
      <Seo title={`${t('seo.title')} Contact Us`} />
      {success ? (
        <Success t={t} />
      ) : (
        <div className="">
          <div className="row ">
            <div
              style={{
                backgroundColor: '#F9F9F9',
              }}
              className="col-12 col-sm-6 d-flex justify-content-end"
            >
              <div className="contactForm__info">
                <FormInfo
                  title={t('contactForm.title')}
                  tag={t('contactForm.tag')}
                  description={t('contactForm.description')}
                  btnLink={'/faq'}
                  btnText={t('contactForm.btn')}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="contactForm__form">
                <Form
                  t={t}
                  validationSchema={contactFormSchema}
                  initialValues={initialValues}
                  onHandelSubmit={onSubmit}
                  formFields={formFields}
                  isSubmiting={isUpdating}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactFormView
